@use '../../scss/index.scss' as *;

.training {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    color: $lobby__text-color;

    background: linear-gradient(0deg, #0B1016 0%, #202F36 100%);

    >h2 {
        position: absolute;
        top: 0;
        width: 100%;
        margin: 10px 0;
        z-index: 1;
    }

    >button {
        z-index: 15;
    }
}

.training__screen {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 50px 80px;

    font-size: 24px;

    color: $beneficial-color;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &_1 { background-image: url('../../assets/img/trainingScreens/training-screen1.jpg'); }
    &_2 { background-image: url('../../assets/img/trainingScreens/training-screen2.jpg'); }
    &_3 { background-image: url('../../assets/img/trainingScreens/training-screen3.jpg'); }
    &_4 { background-image: url('../../assets/img/trainingScreens/training-screen4.jpg'); }
    &_5 { background-image: url('../../assets/img/trainingScreens/training-screen5.jpg'); }
    &_6 { background-image: url('../../assets/img/trainingScreens/training-screen6.jpg'); }
    &_7 { background-image: url('../../assets/img/trainingScreens/training-screen7.jpg'); }
    &_8 { background-image: url('../../assets/img/trainingScreens/training-screen8.jpg'); }
    &_9 { background-image: url('../../assets/img/trainingScreens/training-screen9.jpg'); }
}