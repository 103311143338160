@use '../../scss/index.scss' as *;

.settings {
    &__header {
        height: 40px;
    }
    
    &__main {
        display: flex;        
        flex-direction: column;
    }

    &-button {
        height: 60px;
    }

    input {
        background-color: aliceblue;
        width: 600px;
    }

    button {
        background-color: $card-background-color;

        &:hover {
            background-color: $card-button-color;
            color: black;
        }
    }
}