@use '../../scss/index.scss' as *;

$button-size: 70px;
$svg-size: 75%;

.interactive-button {
    width: $button-size;
    height: $button-size;

    display: flex;
    justify-content: center;
    align-items: center;

    &_platformer-typed {
        @extend %lobby-card;

        position: relative;
        border-radius: 50%;
        border: 1px solid transparent;
    
        color: $platformer-button__action;
        border-color: currentColor;
    
        margin: 0 .3rem;
    
        >svg {
            position: relative;
            width: $svg-size;
            height: $svg-size;
        }
    
        &:active {
            box-shadow: 0 0 8px currentColor;
        }
    }

    &_exit {
        @extend %lobby-card;
        position: absolute;
        top: 0;
        right: 0;
    
        color: currentColor;
    
        width: 40px;
        height: 40px;
    
        margin: 10px;
        padding: 4px 0;
    
        border-radius: 25%;
    
        >svg {
            width: 100%;
            height: 100%;
        }
    }

    &_close {
        $size: 30px;
    
        width: $size;
        height: $size;
    
        background-color: $control-panel__button-color;
        border-radius: 8px;
    
        color: $control-panel__speedometr-background;
        box-shadow: 0 0 5px $control-panel__speedometr-background;
    
        & .close-button__side-plugs {
            fill: $control-panel__button-color;
        }

        .control-panel_full-panel & {
            position: absolute;
            z-index: 2;
            top: 5px;
            right: 20px;
        }

        .control-panel_fire-panel & {
            position: relative;
            top: 5px;
            margin-left: 8px;
        }

        .control-panel_move-panel & {
            position: relative;
            top: 5px;
            margin-left: 8px;
        }
    }

    &__control-panel {
        color: $control-panel__button-color;
        border: 1px solid;
        border-radius: 50%;
    
        background: $control-panel__speedometr-background;
        box-shadow: 0 0 5px $control-panel__speedometr-background;
    
        margin: 8px;

        &.control-disabled {
            pointer-events: none;
            color: $control-panel__metalic-parts;
        }
    
        >svg {
            width: $svg-size;
            height: $svg-size;
        }
    
        &:active {
            box-shadow: 0 0 4px $control-panel__button-color;
        }
    }
}
