@use '../../../scss/index.scss' as *;

$inset: 6px;

.game__control-panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    overflow: hidden;
}

.control-panel {
    position: relative;
    width: 100%;
    height: 100%;

    background-color: #12161C;

    display: flex;
    align-items: flex-end;

    padding: $inset;
}

.control-panel__control-module, .control-panel__action-module {
    position: relative;
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;

    padding: 6px;
}

.control-panel__action-module {
    justify-content: flex-end;
    align-items: flex-start;
    height: calc(100% - 40px);
    padding-bottom: 10px;
    width: 15vw;
}