.contorl-panel__firing-module {
    position: relative;
    width: 10vw;
    height: 10vw;

    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: 40% 60%;

    align-self: flex-end;

    >span {
        position: relative;
        color: #82F2AF;
        font-size: 2.5vh;
        align-self: flex-end;
        text-align: left;
    }
}

.firing-module__button {
    position: relative;
    width: 100%;
    height: 100%;

    border: 1px solid #82F2AF;
    border-radius: 50%;
    
    background-repeat: no-repeat;
    background-position: center;
}

.contorl-panel__detonate-torpedo-button {
    grid-column-start: 2;
    grid-column-end: 2;
    box-shadow: 0px 0px 4px #82f2af80;
    background:
        url('../../../../assets/svg/detonateTorpedoButton.svg'),
        radial-gradient(50% 50% at 50% 50%, #82f2af26 0%, #0000000f 100%), 
        #101F23;
}

.contorl-panel__fire-torpedo-button {
    background:
        url('../../../../assets/svg/fireTorpedoButton.svg'),
        radial-gradient(50% 50% at 50% 50%, #82f2af26 0%, #0000000f 100%), 
        #101F23;
}

.firing-module__button_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.firing-module__button_segment {
    position: absolute;
    width: 115%;
    background-color: #344F48;
    clip-path: polygon(0 0, 3% 0, 3% 100%, 0 100%);

    &.button-segment_active {
        background-color: #82F2AF;
    }
}