@use '../../../scss/index.scss'as *;

.lobby__baot-selection {
    position: relative;
    height: 100%;
    min-width: 180px;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 50px;

    background: $lobby__background_dim;

    box-shadow: -2px 0px 4px #00000040;

    * {
        transition: .2s ease-out;
    }

    background: linear-gradient(270deg, #202F36 0%, #0F141A 100%);
}

.baot-selection__selector-wrapper {
    position: relative;
    height: 32px;
    width: 80%;

    margin: 4px 0;

    &.selector-wrapper_animated {

        >label {
            color: $warning-color;
            transition: 0s !important;
        }

        >input {
            border-color: $warning-color;
            background: linear-gradient(230deg, #ad2444a8 5%, #1E292F 90%);
            background: webkit-linear-gradient(230deg, #ad2444a8 5%, #1E292F 90%);
            transition: 0s !important;
        }
    }

    >label {
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        color: $lobby__text-color;
        font-size: 14px;

        text-shadow: 0 0 2px currentColor;
        text-align: center;

        transform: translateY(-50%);
    }

    >input {
        position: relative;
        width: 100%;
        height: 100%;
        
        color: $lobby__text-color;

        border-radius: 4px;
        background: linear-gradient(90deg, #2b3d47 0%, #1C2930 100%);
        border: 1px solid $lobby__border_dim;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);

        &:checked {
            border: 1px solid $card-button-color;
            background: $card-background-color;
            box-shadow: inset 0 0 10px #3cb5af86;
            
            & + label {
                color: $card-button-color;
            }
        }

        &:disabled {
            border: 1px solid $control-panel__metalic-parts;
            background: $card-background-color;
            box-shadow: none;
            & + label {
                color:  $control-panel__metalic-parts;
            }
        }
    }
}

.baot-selection__title {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 20px;
    font-size: 16px;
    margin: 10px 0;

    color: $lobby__text-color;

    &::after {
        content: '';
        display: block;
        width: 50%;
        height: 1px;
        border-radius: 2px;
        background-color: $lobby__border_dim;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0 -1px 2px rgba(0, 16, 19, 0.4);
    }
}

.lobby__start-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

    width: 80px;
    height: 95px;

    background: linear-gradient(180deg, #154144 0%, #101D25 100%);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    border: 1px solid $lobby__border_dim;

    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: $lobby__text-color;

    &:disabled {
        color: $control-panel__metalic-parts;
        background: none;
        box-shadow: none;
        >div {
            box-shadow: none;
            filter: grayscale(.3);
        }
    }

    >span {
        position: absolute;
        left: 0;
        bottom: 6px;
        color: currentColor;
        font-size: 12px;
        width: 100%;
    }

    >div {
        position: relative;
        width: 50px;
        height: 50px;

        margin-bottom: 8px;

        border-radius: 50%;
        border: 1px solid currentColor;
        box-shadow: inset 0 0 2px #3CB5AE;

        background-image: url('../../../assets/svg/submarine.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            border-radius: 50%;
            background: linear-gradient(210deg, rgba(77, 142, 141, 0.5) 15%, transparent 85%);
        }
    }
}