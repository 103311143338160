@use '../../../../../../../scss/index.scss' as *;

.search-window__crew {
    position: relative;
    width: max-content;
    height: max-content;
    display: flex;
    align-items: flex-end;

    color: $lobby__text-color;

    font-size: 16px;

    border: 1px solid currentColor;
    border-radius: 4px;

    margin: 4px 0;
}

.crew__sailors-amount {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 50px;
    padding: 0 2px;
    border-left: 1px solid currentColor;

    >span {
        font-size: 8px;
        margin-top: 6px;
    }
}

.crew__join-button {
    position: relative;
    height: 100%;
    width: 50px;
    border-left: 1px solid currentColor;
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 30%;
        transform: translateY(-50%);
        background-color: currentColor;
        width: 30%;
        height: 50%;
        clip-path: polygon(0 50%, 100% 100%, 100% 0);
    }
}