@use '../../scss/index.scss' as *;

.lobby {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    min-width: 100%;

    display: flex;
    align-items: flex-start;

    @include backgroundImage('../../assets/img/lobby-background/lobby-background');

    background-size: cover;
    background-position: center;

    color: $lobby__text-color;
}