@use './variables.scss' as *;

@mixin image ( $path ) {
    background-image: url('#{$path}_low-res.jpg'); // fallback
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    object-fit: cover;
    object-position: center;

    content: url('#{$path}@1x.jpg'); // fallback if "image-set" rule isn`t supported

    content: -webkit-image-set(
        url('#{$path}@1x.avif') type('image/avif') 1x,
        url('#{$path}@1x.webp') type('image/webp') 1x,
        url('#{$path}@1x.jpg') type('image/jpg') 1x,
        url('#{$path}@2x.avif') type('image/avif') 2x,
        url('#{$path}@2x.webp') type('image/webp') 2x,
        url('#{$path}@2x.jpg') type('image/jpg') 2x,
    );

    content: image-set(
        url('#{$path}@1x.avif') type('image/avif') 1x,
        url('#{$path}@1x.webp') type('image/webp') 1x,
        url('#{$path}@1x.jpg') type('image/jpg') 1x,
        url('#{$path}@2x.avif') type('image/avif') 2x,
        url('#{$path}@2x.webp') type('image/webp') 2x,
        url('#{$path}@2x.jpg') type('image/jpg') 2x,
    );
}

@mixin backgroundImage ( $path ) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    
    background-image: url('#{$path}@1x.jpg'); // fallback

    background-image: -webkit-image-set(
        url('#{$path}@1x.avif') type('image/avif') 1x,
        url('#{$path}@1x.webp') type('image/webp') 1x,
        url('#{$path}@1x.jpg') type('image/jpg') 1x,
        url('#{$path}@2x.avif') type('image/avif') 2x,
        url('#{$path}@2x.webp') type('image/webp') 2x,
        url('#{$path}@2x.jpg') type('image/jpg') 2x,
    );

    background-image: image-set(
        url('#{$path}@1x.avif') type('image/avif') 1x,
        url('#{$path}@1x.webp') type('image/webp') 1x,
        url('#{$path}@1x.jpg') type('image/jpg') 1x,
        url('#{$path}@2x.avif') type('image/avif') 2x,
        url('#{$path}@2x.webp') type('image/webp') 2x,
        url('#{$path}@2x.jpg') type('image/jpg') 2x,
    );
}