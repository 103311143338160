@use '../../../../../scss/index.scss' as *;

:root {
    --button-height: min(calc(95vh - 90px), 20vw);
    --ui-height: 95vh - 90px;
}

.moveStickOuter {
    width: var(--button-height);
    height: var(--button-height);
    margin-top: calc(var(--ui-height) - var(--button-height));
    margin-left: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    float: left;
    text-align: center;
    vertical-align: middle;

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;

    border-radius: 50%;
    border: 2px solid;

    .platformer__joystick & {
        background-image: url('../../../../../assets/img/whirlpool_transparent_arrowed.png');
        color: $platformer-button__action;
    }
}

.moveStickInner {
    width: 14vw;
    height: 14vw;
}

.stick {
    position: relative;
    width: 14vw;
    height: 14vw;
    border-radius: 50%;
    box-shadow: 0px 0px 10px;

    .platformer__joystick & {
        background-color: rgba(0, 0, 0, 0.541);
        border: 2px solid;
        color: $platformer-button__action;
    }
}

.activeStick {
    box-shadow: 0px 4px 28px;
}