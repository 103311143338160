@use '../../scss/index.scss' as *;
.shop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;

    display: flex;
    flex-direction: column;
    color: $lobby__text-color;

    background: $lobby__background_dim;
}

.shop__header {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;

    >div {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 8px;
        top: 14px;
        font-size: 14px;
        color: $card-button-color;
        text-shadow: 0 0 1px currentColor;

        &::before {
            content: '';
            display: block;
            width: 15px;
            height: 15px;
            background-image: url('../../assets/svg/dollar-sign.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 50%;
        }
    }
}