@use '../../../../../scss/index.scss' as *;

.crew-manager__crew-list {
    @extend %lobby-card;

    position: relative;
    flex-direction: column;
    padding: 4px;

    width: 140px;
    max-height: 60vh;
    overflow: auto;
    margin: 0 4px;

    box-shadow: none;
    border-radius: 6px;
    
    >div {
        margin: 1px 0;
        &:last-child {
            border: none;
        }
    }
}