@use '../../../scss/index' as *;

.lobby__extra-buttons-wrapper {
    position: relative;
    width: 80px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    padding: 8px;

    background: linear-gradient(270deg, rgba(255, 255, 255, 0.1) 0%, transparent 100%);
}

.lobby__extra-button {
    @extend %lobby-card;

    position: relative;
    width: 55px;
    height: 60px;
    margin: 8px;

    >span {
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);
        color: currentColor;
        text-shadow: 0 0 2px currentColor;
        font-size: 10px;
    }

    >div {
        position: relative;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid currentColor;
        margin-bottom: 8px;
        background: $card-button__icon-background;
        box-shadow: 0 0 2px currentColor;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: 60%;
        }
    }

    &.extra-button_shop {
        >div {
            &::after {
                background-image: url('../../../assets/svg/shopping-cart.svg');
                background-position: 45% 55%;
            }
        }
    }

    &.extra-button_training {
        >div {
            &::after {
                background-image: url('../../../assets/svg/learning-book.svg');
                background-position: 55% 70%;
            }
        }
    }

    &.extra-button_settings {
        >div {
            &::after {
                background-image: url('../../../assets/svg/settings.svg');
                background-position: 55% 70%;
            }
        }
    }
}