@use '../../../../scss/index.scss' as *;

.shop-page__purchase-card {
    @extend %lobby-card;

    position: relative;
    margin: 0px 15px;

    width: 30vh;
    height: 30vh;

    min-width: 90px;
    min-height: 90px;

    max-width: 160px;
    max-height: 160px;

    font-size: 14px;

    flex-direction: column;

    &.purchase-card_maxed { background-color: #f8912963; }

    &:active {
        box-shadow: 0 0 8px currentColor;
    }

    &:disabled {
        pointer-events: none;
    }

    >span {
        position: absolute;
        padding: 0 4px;
        bottom: 4px;
    }
}

.purchase-card__icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;

    font-size: 6px;

    border-radius: 50%;
    border: 1px solid currentColor;

    display: flex;
    align-items: center;
    justify-content: center;

    >div {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $lobby__border_dim;
        z-index: 0;
        clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    }

    >span { z-index: 1; }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: -15%;
        right: 0;
        width: 6px;
        height: 10px;
        background-color: currentColor;
    }
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: -45%;
        right: -3px;
        width: 12px;
        height: 6px;
        background-color: currentColor;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }
}

.purchase-card__cost {
    position: absolute;
    top: 4px;
    left: 4px;
    width: max-content;
    height: 15px;

    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background-image: url('../../../../assets/svg/dollar-sign.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
        border-radius: 50%;
        border: 1px solid currentColor;
        margin-right: 2px;
    }
}