.usePanel {
    position: absolute;
    bottom: 0;
    right: 0;

    display: flex;

    height: max-content;
    width: max-content;

    padding: .5rem;
}