.action-module__speedometer-wrapper {
    position: relative;
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: flex-end;
    width: 55%;
    height: auto;
    max-height: 75vh;
    margin-bottom: 10px;

    color: #82F2AF;
    
    >span {
        font-size: 3vh;
        writing-mode: vertical-rl;
        text-orientation: upright;
        margin-top: 20%;
        margin-left: 4px;
    }
}

.action-module__speed-control-wrapper {
    position: relative;
    width: 70%;
    height: 100%;

    display: flex;
    align-items: flex-end;

    border-radius: 4vh;

    &::after {
        content: '';
        width: calc(100% + 2px);
        height: 90%;
        background-color: #344F48;
        position: absolute;
        left: -1px;
        border-radius: inherit;
        z-index: -1;
    }
}

.action-module__speed-control-module {
    position: relative;
    width: 100%;
    height: 90%;

    border: 1px solid #82F2AF;
    border-right: none;
    border-left: none;
    border-radius: inherit;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 1;
    background-color: #12161C;
}

.action-module__speed-setter {
    -webkit-appearance: none;
    appearance: none;
    position: absolute;

    width: 100%;
    height: calc(80% + 16px);
    writing-mode: vertical-lr;
    z-index: 1;

    &:focus { outline: none; }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        position: relative;
        width: 5vh;
        height: 5vh;
        border-radius: 50%;
        border: 1px solid #82F2AF;
        box-shadow: 0 0 4px #82f2af73;
        background: url('../../../../assets/svg/speedSetterThumb.svg'), -webkit-radial-gradient(60% 60% at 40% 60%, #45ffbb1f 0%, #ffffff00 100%), #101F23;
        background: url('../../../../assets/svg/speedSetterThumb.svg'), radial-gradient(60% 60% at 40% 60%, #45ffbb1f 0%, #ffffff00 100%), #101F23;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        z-index: 1;
    }

    &::-webkit-slider-runnable-track {
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    &::-moz-range-thumb {
        position: relative;
        width: 5vh;
        height: 5vh;
        border-radius: 50%;
        border: 1px solid #82F2AF;
        box-shadow: 0 0 4px #82f2af73;
        background: url('../../../../assets/svg/speedSetterThumb.svg'), -webkit-radial-gradient(60% 60% at 40% 60%, #45ffbb1f 0%, #ffffff00 100%), #101F23;
        background: url('../../../../assets/svg/speedSetterThumb.svg'), radial-gradient(60% 60% at 40% 60%, #45ffbb1f 0%, #ffffff00 100%), #101F23;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        z-index: 1;
    }

    &::-moz-range-track {
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    &::-ms-thumb {
        position: relative;
        width: 5vh;
        height: 5vh;
        border-radius: 50%;
        border: 1px solid #82F2AF;
        box-shadow: 0 0 4px #82f2af73;
        background: url('../../../../assets/svg/speedSetterThumb.svg'), -webkit-radial-gradient(60% 60% at 40% 60%, #45ffbb1f 0%, #ffffff00 100%), #101F23;
        background: url('../../../../assets/svg/speedSetterThumb.svg'), radial-gradient(60% 60% at 40% 60%, #45ffbb1f 0%, #ffffff00 100%), #101F23;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        z-index: 1;
    }

    &::-ms-track {
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    &::-ms-fill-upper {
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    &::-ms-fill-lower {
        background: transparent;
        border-color: transparent;
        color: transparent;
    }
}

.speed-setter__track {
    position: absolute;
    z-index: 0;
    height: 80%;
    width: 6%;
    border-radius: 1vh;
    
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    background: linear-gradient(0deg, #11C6D2 0%, #82F2AF 50%, #D26211 100%);

    &::after, &::before {
        content: '';
        display: block;
        width: 200%;
        height: auto;
        aspect-ratio: 1;

        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        border-radius: 50%;
    }

    &::before {
        bottom: 0;
        background: linear-gradient(0deg, #82F2AF 0%, #11C6D2 100%);
    }

    &::after {
        top: 0;
        background: linear-gradient(0deg, #D26211 0%, #82F2AF 100%);
    }
}

@supports not (aspect-ratio: 1) {
    .speed-setter__track {
        &::after, &::before {
            display: none;
        }
    }
}

#speed-setter-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;
    position: absolute;
    width: fit-content;
    height: calc(80% + 8px);
    font-size: 2vh;
    z-index: 1;
    transform: translateX(-50%);
    left: 0;

    & option {
        position: relative;
        background-color: #12161C;
        border-radius: inherit;
    }
}

.speed-setter__current-track {
    position: absolute;
    left: -1px;
    width: calc(100% + 2px);
    height: 90%;
    border-radius: inherit;
    z-index: 0;
    overflow: hidden;
    background: linear-gradient(0deg, #82F2AF 0%, #11C6D2 25%, #344F48 50%, #D26211 75%, #82F2AF 100%);
    clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
    transition: clip-path .2s linear;
}

.action-module__speed-indicator {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;

    width: 60%;
    height: auto;
    aspect-ratio: 1;

    border-radius: 50%;
    background-color: #101F23;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0 0 4px #82f2af4b;

    span {
        display: block;
        width: 90%;
        height: 90%;

        border-radius: 50%;
        border: .8vh solid currentColor;
        background-color: #101F23;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: min(1.8vh, 1vw);
    }
}