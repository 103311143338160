.control-module__info {
    width: 100%;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    margin-bottom: 4%;

    >div {
        position: relative;
        width: 50%;
        max-width: 50%;
        height: 100%;
        margin: 0 4%;
    }
}