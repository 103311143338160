.control-module__depth {
    display: flex;
    align-items: center;
    flex-direction: column;

    >span {
        font-size: 4vh;
        margin-bottom: 10%;
    }
}

.control-module__depthmeter-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    width: 100%;
    flex-grow: 1;

    color: #11C6D2;
}

.control-module__depth-indicator {
    width: 25%;
    height: 85%;

    border-radius: 2vh;
    border: 1px solid currentColor;

    background-color: #133034;

    overflow: hidden;
}

.control-module__depthmeter {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 40%;

    transform: translateX(-100%);
    aspect-ratio: 1;

    background-color: #133034;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0 0 4px currentColor;

    font-size: 2vh;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);

        width: 90%;
        height: 90%;
        border-radius: inherit;
        border: .7vh solid currentColor;
    }
}

.depth-indicator__current-depth {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 100%;
    background-color: currentColor;
}