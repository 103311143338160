@use '../../../../../../../scss/index.scss' as *;

.wide-crew-list__detailed-sailor {
    position: relative;
    width: max-content;
    height: max-content;
    display: flex;
    align-items: flex-end;

    color: $lobby__text-color;

    font-size: 16px;

    border: 1px solid currentColor;
    border-radius: 4px;

    margin: 4px 0;

    &.detailed-sailor_captain {
        color: #62bb9a;
    }

    &.detailed-sailor_user {
        color: $card-button-color !important;
    }
}

.detailed-sailor__name-info {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 100%;
    width: 300px;

    >span { text-shadow: 0 0 1px currentColor; }

    >.name-info__rating {
        font-size: 12px;
        margin: 4px;
    }
}

.detailed-sailor__rank-info {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 30px;
    height: 100%;

    border-right: 1px solid currentColor;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.rank-info {
    &_sailor {
        background-image: url('../../../../../../../assets/img/crewSprites/sailor.png');
    }
    &_foreman {
        background-image: url('../../../../../../../assets/img/crewSprites/foreman.png');
    }
    &_midshipman {
        background-image: url('../../../../../../../assets/img/crewSprites/midshipman.png');
    }
    &_lieutenant {
        background-image: url('../../../../../../../assets/img/crewSprites/lieutenant.png');
    }
    &_captain {
        background-image: url('../../../../../../../assets/img/crewSprites/captain.png');
    }
    &_admiral {
        background-image: url('../../../../../../../assets/img/crewSprites/admiral.png');
    }
}

.detailed-sailor__kick-button {
    position: relative;
    height: 100%;
    width: 45px;
    border-left: 1px solid currentColor;

    &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        height: 1px;
        width: 80%;
        transform-origin: center;
        background-color: $warning-color;
        border-radius: 50%;
        box-shadow: 0 0 2px $warning-color;
    }

    &::before {
        transform: translate(-50%, -50%) rotateZ(45deg);
    }

    &::after {
        transform: translate(-50%, -50%) rotateZ(-45deg);
    }
}