.joystickContentContainer {
    background-size: cover;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    display: flex;
    flex-direction: column;
}

.shotBtnWrapper {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.shotBtnContainer {
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.moveStickContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}