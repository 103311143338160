@use '../../../../../../scss/index.scss' as *;

.crew-list__sailor {
    width: 100%;
    max-height: max-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    color: $lobby__text-color;

    padding: 2px 4px;

    font-size: 10px;

    text-shadow: 0 0 1px currentColor;

    &.sailor__captain {
        color: #62bb9a;
    }

    &.sailor_user {
        color: $card-button-color !important;
    }

    border-bottom: 1px solid $lobby__border_dim;
}