@use '../../../../scss/index.scss' as *;

.crew-info__crew-manager {
    position: absolute;
    left: 0;
    top: 40px;
    width: 300px;
    height: max-content;
    margin-left: 4px;

    display: flex;
    flex-direction: column;
}

.crew-manager__manage-button {
    @extend %lobby-card;

    width: 120px;
    height: 25px;
    border-radius: 6px;
    font-size: 11px;

    margin: 8px 4px;

    &.manage-button_active {
        box-shadow: inset 0 0 10px #3cb5af86;
    }
}

.crew-manager__popup-window {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-height: max-content;

    overflow-y: auto;
    z-index: 10;

    background: $lobby__background_dim;

    >h2 {
        font-size: 16px;
        margin: 14px 0;
        color: $lobby__text-color;
        text-shadow: 0 0 2px currentColor;
    }
}

.popup-window__list {
    position: relative;
    width: 100%;
    height: calc(100% - 50px);
    max-height: max-content;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.crew-manager__close-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;

    color: $lobby__text-color;

    &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        height: 1px;
        width: 80%;
        transform-origin: center;
        background-color: currentColor;
        border-radius: 50%;
        box-shadow: 0 0 2px currentColor;
    }

    &::before {
        transform: translate(-50%, -50%) rotateZ(45deg);
    }

    &::after {
        transform: translate(-50%, -50%) rotateZ(-45deg);
    }
}