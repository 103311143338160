.control-panel__sonar {
    position: relative;

    flex-grow: 1;
    height: 98%;
    z-index: 0;

    margin: 0 4px;
}

.sonar__user-name {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 10px;
}

.sonar__canvas-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    
    >span { opacity: .5; }

    >.canvas-overlay__sonar-range {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(calc(-50% - 7px));
        font-size: 10px;
    }
}

#sonar-field {
    position: relative;
    width: 100%;
    height: 100%;

    >* {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }

    >canvas {
        background-image: url('../../../../assets/img/sonarSphere.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        clip-path: circle(50% at 50% 50%);
    }
}