@use '../../scss/index.scss' as *;

.game {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    min-width: 100%;
    font-size: 0.6rem;
    font-family: "Quantico", sans-serif;
    color: $platformer-button__spotlight-color;
}