@use '../../scss/index.scss' as *;

.slider {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.slider__content {
    position: relative;
    display: flex;
    transition: transform 0.5s ease;
    width: 100%;
    height: 100%;
}

.slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
}

.slider__button {
    @extend %lobby-card;

    position: absolute;
    bottom: 20px;
    z-index: 5;
    width: 50px;
    height: 50px;

    color: $lobby__text-color;

    &:active {
        box-shadow: 0 0 8px currentColor;
    }

    &::after {
        content: '';
        display: block;
        background-color: currentColor;
        width: 30%;
        height: 30%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-40%, -50%);
        clip-path: polygon(100% 50%, 0 0, 0 100%);
    }

    &.prev {
        left: 10px;
        transform: rotateZ(180deg);
    }

    &.next {
        right: 10px;
    }
}