@use '../../scss/index.scss' as *;

.popup {
    position: absolute;
    top: 5px;
    left: 10px;
    z-index: 100;

    height: 60px;
    width: max-content;
    padding: 8px;

    border-radius: 4px;
    border-right: 1px solid currentColor;
    border-left: 1px solid black;
    
    display: flex;
    align-items: center;

    pointer-events: none;

    &__warning {
        color: $warning-color;
        background: linear-gradient(280deg, rgba(0, 0, 0, 0) 40%, rgba(135, 0, 30, 0.2) 80%), rgba(0, 0, 0, 0.6);
    }

    &__neutral {
        color: $neutral-color;
        background: linear-gradient(280deg, rgba(0, 0, 0, 0) 40%, rgba(181, 255, 251, 0.2) 80%), rgba(0, 0, 0, 0.6);
    }

    &__beneficial {
        color: $beneficial-color;
        background: linear-gradient(280deg, rgba(0, 0, 0, 0) 40%, rgba(69, 255, 188, 0.2) 80%), rgba(0, 0, 0, 0.6);
    }
}

.popup__message {
    margin: 0 8px;
    >* { text-shadow: 0 0 2px currentColor; }
    >h2 { 
        font-size: 14px;
        margin: 4px 0;
    }
    >h3 { font-size: 12px; }
}

.popup__icon {
    position: relative;
    min-width: 40px;
    height: 40px;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    border-radius: 50%;

    box-shadow: 0 0 6px black;

    opacity: .8;

    border: 1px solid;

    .beneficial__base_inside & {
        background-image: url('../../assets/img/messages/message__repair-icon.png');
        background-size: 105%;
    }

    .beneficial__snowflake & {
        background-image: url('../../assets/img/messages/message__snowflake-icon.png');
    }

    .neutral__base_outside & {
        background-image: url('../../assets/img/messages/message__warning-icon.png');
    }

    .neutral__sailor & {
        background-image: url('../../assets/img/messages/message__leave-crew.png');
    }

    .neutral__rating & {
        background-image: url('../../assets/img/messages/message__rating-icon.png');
    }

    .neutral__money & {
        background-image: url('../../assets/img/messages/message__money-icon.png');
    }

    .warning__fire & {
        background-image: url('../../assets/img/messages/message__fire-icon.png');
    }

    .warning__drown & {
        background-image: url('../../assets/img/messages/message__drown-icon.png');
        background-size: 105%;
    }

    .warning__death & {
        background-image: url('../../assets/img/messages/message__death.png');
        background-size: 105%;
    }
}