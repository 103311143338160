.control-module__button-wrapper {
    position: relative;

    width: 28%;
    height: 28%;

    pointer-events: all;

    margin: 0 10%;
}

.control-module__button {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    border: 1px solid;
    border-radius: 50%;

    background: radial-gradient(45% 45% at 40% 55%, #00c5f10c 0%, #00000018 100%), #101f23;

    &_course {
        box-shadow: 0px 0px 10px #ff751225, 0px 0px 2px #c04500, inset 0px 3px 4px #00000025;
        border-color: #D26211;
    }

    &_pitch {
        box-shadow: 0px 0px 10px #12F1FF25, 0px 0px 2px #0092C0, inset 0px 3px 4px #00000025;
        border-color: #11BBD2;
    }

    &:active {
        background: radial-gradient(45% 45% at 40% 55%, #00c5f10c 0%, #00000018 100%), #081113;
    }
}

.control-module__arrows-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    pointer-events: none;
}

.control-module__arrow {
    position: relative;
    width: 12.5%;
    height: 30%;
    margin-left: 5%;

    color: black;
    -webkit-filter: drop-shadow(0px 0px 2px currentColor);
    filter: drop-shadow(0px 0px 2px currentColor);
    
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: currentColor;
        clip-path: polygon(0 25%, 0 0, 100% 50%, 0 100%, 0 75%, 40% 50%);
    }

    &_lighted {
        .control-module__pitch & {
            color:  #11C6D2;;
        }
        .control-module__course & {
            color: #D26211;
        }
    }
}