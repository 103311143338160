@use './scss/index.scss' as *;

@font-face {
    font-family: 'Play';
    src: url('./assets/fonts/Play-Regular.ttf');
}

@font-face {
    font-family: 'Quantico';
    font-style: normal;
    src: url('./assets/fonts/Quantico-Regular.ttf');
}

.app {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;

    text-align: center;
    background-color: $main-theme;
}

.rotateContainer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    width: 100%;
    height: 100vh;

    >img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;

        @include image('./assets/img/rotate-mobile_screen/rotate-mobile_screen');
    }
}

button {
    font-size: 2vh;
}