@use '../../scss/index.scss' as *;

.user-info-old {
    &__rank, 
    &__rating, 
    &__money {
        display: grid;
        grid-template-columns: max-content max-content;
        grid-template-rows: max-content;
        color: $card-button-color;
        text-shadow: #000 2px 0 10px;
    
        >span {
            margin: 4px;
        }
    }
    
    & {
        &-bold {
            color: $card-button-color;
            font-weight: bold;
        }
    }    
}