@use '../../../../../../../scss/index.scss' as *;

.control-module__course-indicator {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    
    background-image: url('../../../../../../../assets/img/sonarImages/azimuth.png');
}