$inset: 6px;

.control-panel__frame {
    width: calc(100% - calc($inset * 2));
    height: calc(100% - calc($inset * 2));
    position: absolute;
    top: $inset;
    left: $inset;

    border-radius: 20px;
    overflow: hidden;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid #133034;
        z-index: 0;
    }
}

.control-panel__frame-angle {
    position: absolute;

    width: 40px;
    height: 40px;

    background-color: #133034;
    border-radius: 50%;
    border: 1px solid #82F2AF;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        content: '';
        display: block;

        position: absolute;
        top: 50%;
        left: 100%;
        width: 40vw;
        height: 1px;

        background: linear-gradient(90deg, #82F2AF 0%, #133034 100%);
    }

    &::before {
        content: '';
        display: block;

        position: absolute;
        top: 100%;
        left: 50%;
        height: 30vh;
        width: 1px;

        background: linear-gradient(180deg, #82F2AF 0%, #133034 100%);
    }

    &:nth-child(1) {
        top: 0;
        left: 0;

        transform: translate3d(-50%, -50%, 0);
    }

    &:nth-child(2) {
        bottom: 0;
        left: 0;

        transform: translate3d(-50%, 50%, 0) rotateZ(180deg) rotateY(180deg);
    }

    &:nth-child(3) {
        bottom: 0;
        right: 0;

        transform: translate3d(50%, 50%, 0) rotateZ(180deg);
    }

    &:nth-child(4) {
        top: 0;
        right: 0;

        width: 40px;
        height: 40px;

        border-top: none;
        border-left: none;

        border-radius: 0px 0px 15px 0px;

        transform: rotateY(180deg);

        &::after { top: 0; }

        &::before { left: 0; }
    }
}

.control-panel__close-icon {
    position: relative;
    width: 70%;
    height: 70%;
    border-radius: 50%;
    border: 1px solid #82F2AF;

    background:
        url('../../../../assets/svg/controlPanelCloseButton.svg'), 
        radial-gradient(50% 50% at 50% 50%, #82f2af14 0%, #0000000a 100%), 
        #12161C;
}