$main-theme: #102130;

$platformer-button__spotlight-color: #55ddff;
$platformer-button__spotlight-color_transparent: #55ddffbb;
$platformer-button__action: #88ddff;

$contol-module__fade-color: #75d2e979;
$control-panel__metalic-parts: #3f4f57;
$control-panel__button-color: #f89129;
$control-panel__spot-color_transparent: #ff8000b0;
$control-panel__speedometr-background: #121318;

$warning-color: #f50039;
$neutral-color: #B5FFFB;
$beneficial-color: #45FFBC;

$lobby__background_dim: linear-gradient(180deg, #202F36 0%, #0F141A 100%);
$lobby__border_dim: #2c7c7b41;
$lobby__text-color: #A9B8B9;
$card-button-color: #c4977a;
$card-background-color: #202f36bd;
$card-button__icon-background: radial-gradient(50% 50% at 50% 50%, #BA8944 0%, rgba(21, 65, 68, 0.575) 100%);

