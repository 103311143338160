@use '../../../scss/index.scss' as *;

.lobby__party-list {
    position: relative;
    width: 100%;
    height: 100px;

    color: $card-button-color;
}

.lobby__user-name {
    position: absolute;
    top: 4px;
    left: 8px;
    color: currentColor;
    font-size: 16px;
    text-shadow: 0 0 1px currentColor;
}

.lobby___rank {
    position: absolute;
    top: 4px;
    right: 8px;
    width: 100px;
    height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    >span {
        font-size: 12px;
        text-shadow: 0 0 1px currentColor;
    }

    >svg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}