@use '../../../../../scss/index.scss' as *;

:root {
    --button-height: min(calc(95vh - 90px), 25vw);
    --ui-height: 95vh - 30px;
}

.movePadOuter {
    width: var(--button-height);
    height: var(--button-height);
    margin-top: calc(var(--ui-height) - var(--button-height));
    margin-left: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    float: left;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;

    color: $platformer-button__action;

    .platformer__joystick & {
        background-image: url('../../../../../assets/img/whirlpool_transparent.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border: 2px solid;
    }
}

.safariFix .movePadOuter {
    height: calc(var(--button-height) - 30px);
    width: calc(var(--button-height) - 30px);
}

.movePadBtnWrapper {
    width: calc(var(--button-height) * 0.65);
    height: calc(var(--button-height) * 0.65);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: rotate(45deg);
}

.movePadBtnRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.movePadBtn {
    border-radius: 50%;
    border: 1px solid;

    box-shadow: none;
    height: calc(var(--button-height) / 3.3);
    width: calc(var(--button-height) / 3.3);

    background-repeat: no-repeat;
    background-position: center 40%;
    background-color: #00000050;
    background-size: 70%;

    &.up { transform: rotate(-45deg); }

    &.right { transform: rotate(45deg); }

    &.down { transform: rotate(135deg); }

    &.left { transform: rotate(-135deg); }

    .platformer__joystick & {
        color:  $platformer-button__action;
        background-image: url('../../../../../assets/img/platformer__arrow.png');
    }
}

.movePadBtn:active {
    .platformer__joystick & {
        box-shadow: 0 0 4px $platformer-button__spotlight-color;
        background-color: $platformer-button__spotlight-color;   
    }
}