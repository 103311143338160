@use './variables.scss' as *;

%lobby-card {
    display: flex;
    align-items: center;
    justify-content: center;

    color: $card-button-color;

    border: 1px solid currentColor;
    border-radius: 10%;

    background-color: $card-background-color;
    box-shadow: 0 0 3px currentColor;
}

%lobby__popup-window {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-height: max-content;

    overflow-y: hidden;
    z-index: 10;

    background: $lobby__background_dim;
}