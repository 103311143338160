.lobby__user-info {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
}

.user-info__namespace {
    position: absolute;
    top: 4px;
    left: 8px;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    >span {
        font-size: 14px;
        text-shadow: 0 0 2px currentColor;
    }

    >div {
        display: flex;
        align-items: center;
        justify-content: center;

        * {
            font-size: 10px;
            text-shadow: 0 0 2px currentColor;
        }
    }
}

.user-info__money {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;

    >div {
        height: 12px;
        width: 12px;

        border-radius: 50%;
        border: 1px solid currentColor;
        box-shadow: 0 0 2px currentColor;

        background-image: url('../../../../assets/svg/dollar-sign.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 50%;

        margin-right: 2px;
    }
}