.control-module__control-wrapper {
    position: relative;
    width: var(--button-height);
    height: var(--button-height);

    color: #133034;
    border-radius: 50%;
    box-shadow: 0px 0px 4px #82f2af7c;

    >div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    
        display: flex;
        justify-content: space-between;
        align-items: center;

        pointer-events: none;

        &.disabled {
            filter: grayscale(80%) brightness(80%);

            & * {
                pointer-events: none !important;
            }
        }

        >.control-module__button-wrapper:nth-child(1) {
            transform: rotateZ(180deg);
        }
    }

    .control-module__pitch {
        transform: rotateZ(90deg);
    }

    &::before {
        content: '';
        display: block;
        width: 101%;
        height: 101%;
        position: absolute;
        top: -.5%;
        left: -.5%;
        border-radius: inherit;
        padding: 2px;

        background: 
            linear-gradient(45deg, #82f2af 0%, #133034 50%);
            -webkit-mask: 
                linear-gradient(#fff 0 0) content-box, 
                linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
                    mask-composite: exclude; 
    }

    &::after {
        content: '';
        display: block;

        width: 10%;
        height: 1.5%;
        position: absolute;

        top: -3%;
        left: 50%;

        transform: translateX(-50%);

        background: url('../../../../../assets/svg/courseIndicatorArrow.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
}