@use '../../../../../scss/index.scss' as *;

.user-info__user-rank {
    @extend %lobby-card;
    $svg-size: 30px;

    position: absolute;
    top: 4px;
    right: 8px;

    width: 55px;
    min-height: 60px;
    height: max-content;
    padding: 4px 0;

    flex-direction: column;

    >span {
        width: 100%;
        max-width: 100%;
        font-size: 8px;
        padding: 0 2px;
        margin: 1px 0;
    }

    >div {
        position: relative;
        width: $svg-size;
        max-height: $svg-size;
        height: $svg-size;
        min-height: $svg-size;
        margin: 2px 0;

        display: flex;
        align-items: center;
        justify-content: center;
    
        >span {
            font-size: 8px;
        }

        >svg {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;         

            >circle {
                fill: none;
                stroke-width: 1px;
                stroke: $card-button-color;
            }
        }
    }
}