@use '../../../../../../scss/index.scss' as *;

.crew-manager__empty-search {
    position: absolute;
    top: 40%;
    font-size: 20px;
    width: 100%;
    transform: translateY(-50%);
    color: $lobby__text-color;
    text-shadow: 0 0 2px currentColor;
}

.popup-window__info {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: max-content;
    height: 30px;
    color: $lobby__text-color;

    >span {
        display: block;
        width: 50px;
        font-size: 10px;
        &:first-child {
            font-size: 14px;
            width: 330px !important;
        }
    }
}