@use '../../../scss/index.scss' as *;

.shop__shop-page {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 50px 25px 50px;

    overflow-y: auto;

    >span {
        font-size: 20px;
        position: absolute;
        top: 10px;
        text-shadow: 0 0 2px currentColor;
    }
}

.shop-page__purchase-submarine {
    @extend %lobby-card;

    position: relative;

    width: 25vh;
    height: 25vh;

    min-width: 80px;
    min-height: 80px;

    max-width: 150px;
    max-height: 150px;

    margin: 8px 0;
    font-size: 12px;

    >span {
        margin: 0 4px;
    }
}

.shop-page__upgrade-block {
    width: 100%;
    height: max-content;

    flex-grow: 1;
    display: flex;
    flex-direction: column;

    >span {
        font-size: 12px;
    }

    >div {
        position: relative;

        flex-grow: 1;
        width: 100%;

        display: flex;
        align-items: flex-start;
        justify-content: center;

        padding: 8px;
    }
}