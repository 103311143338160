.control-module__angles {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.control-module__angle {
    position: relative;
    width: auto;
    height: min(40%, calc(25vw / 4));
    
    color: #133034;

    border: 2px solid;
    border-radius: 50%;

    aspect-ratio: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;

        display: block;
        width: 100%;
        height: 1px;
        
        background-color: currentColor;
    }

    &_course {
        background-color: #D26211;
        &::after { 
            transform: translateY(-50%) rotateZ(90deg); 
        }
        >.control-module__angle-indicator {
            color: #D26211;
        }
    }

    &_pitch {
        background-color: #11C6D2;
        >.control-module__angle-indicator {
            top: 0;
            transform: translateY(-50%);
            color: #11C6D2;
        }
    }
}

.angle-indicator__icon {
    width: 80%;
    height: 80%;

    background-repeat: no-repeat;
    background-size: contain;

    &_pitch-torpedo {
        background-position: center;
        background-image: url('../../../../../../assets/svg/torpedoPitch.svg');
    }
    &_pitch-submarine {
        background-position: center 42%;
        background-image: url('../../../../../../assets/svg/submarinePitch.svg');
    }
    &_course-torpedo {
        background-position: center;
        background-image: url('../../../../../../assets/svg/torpedoCourse.svg');
    }
    &_course-submarine {
        background-position: center;
        background-image: url('../../../../../../assets/svg/submarineCourse.svg');
    }
}

.control-module__angle-indicator {
    position: absolute;
    bottom: 0;
    right: 0;

    width: 45%;
    height: 45%;

    color: inherit;
    background-color: #133034;
    border-radius: inherit;

    display: flex;
    justify-content: center;
    align-items: center;

    transform: translateY(50%);

    font-size: 2vh;
    box-shadow: 0 0 4px currentColor;

    &::after {
        content: '';

        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 0;
        transform: translate3d(-50%, -50%, 0);

        display: block;
        width: 90%;
        height: 90%;

        border-radius: inherit;
        border: 1px solid currentColor;
    }
}